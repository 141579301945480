#topmenu {
	background: white;
	padding: 0;
}

#topmenu .navbar-brand {
	height: 65px;
	width: 228px;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0;
}

#topmenu .navbar-brand img {
	height: 39px;
}

#topmenu .search {
	margin-bottom: 0 !important;
}

#topmenu .search .input-group-text {
	background: #609bbd;
}

#topmenu .search .input-group-text {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

#topmenu .search .input-group-text i {
	color: white;
}

#topmenu .search input[type="search"] {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

#topmenu .search input[type="search"]:focus {
	-webkit-appearance: none;
	box-shadow: none;
	-webkit-box-shadow: none;
}

#topmenu .top-control:hover {
	cursor: pointer;
}

#topmenu .top-control .icon i {
	font-size: 25px;
	color: #609bbd;
}
.dropdown-menu.active {
	display: block;
}
.notifications-main {
	width: 60%;
	margin: auto;
	border-radius: 8px;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.notification-area {
	background-color: white;
	display: none;
	position: absolute;
	top: 26px;
	right: 0px;
	width: 100vw;
	max-width: 400px;
	min-width: 300px;
	border-radius: 4px;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.notification-area.active {
	display: block;
}
.c-badge {
	margin-left: -8px;
	font-size: xx-small;
}
.notification-line {
	margin-top: 2px;
	margin-bottom: 2px;
}
.notification-line:last-of-type {
	display: none !important;
}
.notification-area .card-body {
	border-radius: 4px;
	width: 88%;
	margin: auto;
}

.notification-area .card-body p {
	margin-bottom: 0;
}
.notification-area .card-body {
	padding: 1rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}
.notification-time {
	padding-top: 8px;
	font-weight: 500;
	color: #9c9c9c;
	font-size: 14px;
}
.notification-unread {
	background-color: #609bbd1b;
}
.notification-unread .card-body {
	position: relative;
}
.notification-unread .card-body::after {
	content: " ";
	display: block;
	width: 8px;
	height: 8px;
	background-color: #609bbdda;
	border-radius: 10px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%) translateX(4px);
}
.single-notification {
	position: relative;
	transition: all 150ms ease;
}
.single-notification:hover {
	background-color: #00000008;
}
.notification-border {
	width: 88%;
	background-color: #00000014;
	height: 1px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
.no-notifications {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: rgb(152, 152, 152);
}

.notification-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px;
}
.notification-filters {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 10px;
}
.all-notifications {
	padding: 8px;
	background: white;
	border-radius: 10px;
}

.all-notifications:hover {
	background-color: #abcfe4;
	color: white;
	cursor: pointer;
}
.Unread-notifications {
	padding: 8px;
	background: white;
	border-radius: 10px;
	margin-left: 10px;
}
.Unread-notifications:hover {
	background-color: #abcfe4;
	cursor: pointer;
	color: white;
}

.selected-color {
	background-color: #609bbd;
	color: white;
}
.read-all {
	color: #609bbd;
	font-size: 16px;
	font-weight: 500;
	border-radius: 8px;
	padding: 8px;
	transition: all 150ms ease;
	cursor: pointer;
}
.read-all:hover {
	color: white;
	background-color: #609bbd;
}
.un-tooltip {
    position: relative;
    cursor: pointer;
}

.un-tooltip:hover::after,
.un-tooltip:hover::before {
    display: block;
}

.un-tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 150%; /* Position above the element, a bit further for space */
    left: 50%;
    transform: translateX(-50%);
    background-color: #464646;
    color: #fff;
    padding: 8px 12px; /* Added padding for a rounded effect */
    border-radius: 8px;
    white-space: nowrap;
    font-size: 14px;
    display: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adds a shadow for depth */
    z-index: 10;
}

.un-tooltip::before {
    content: '';
    position: absolute;
    bottom: 115%; /* Adjust to align with tooltip box */
    left: 50%;
    transform: translateY(50%) translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: #464646 transparent transparent transparent;
    display: none;
    z-index: 9;
}
