#dashboard {
	display: flex;
	justify-content: center;
	align-items: center;
}
.dashboardImage {
	max-width: 440px;
}
.admin-date-filters {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 10px;
	margin-bottom: 66px;
	margin-top: 1px;
}
.admin-date-filters input {
	padding: 5px 16px;
	border: 2px solid #609bbd;
	border-radius: 4px;
	position: relative;
	background-color: whitesmoke;
	font-size: 18px;
	cursor: unset;
}

.admin-date-filters input::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

.admin-date-filters input:first-child::before {
	content: "From";
	display: block;
	position: absolute;
	top: 0;
	transform: translateY(-60%);
	background-color: whitesmoke;
	font-size: 14px;
}
.admin-date-filters input:last-child:before {
	content: "To";
	display: block;
	position: absolute;
	top: 0;
	transform: translateY(-60%);
	background-color: whitesmoke;
	font-size: 14px;
}
.admin-card {
	cursor: pointer;
	transition: all 0.12s ease !important;
}
.admin-card:active {
	background-color: rgb(241, 241, 241);
	transform: scale(0.9);
}
.admin-card:hover {
	background-color: rgb(241, 241, 241);
}
#route-view {
	padding: 15px 15px 30px;
	overflow-y: auto;
	height: inherit;
}
.main-heading {
	color: #111;
	font-size: 22px;
	font-weight: 600;
	display: flex;
}
.main-heading .side-link {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}
.main-heading .side-link .btn-side {
	background: #609bbd;
	color: white;
	font-size: 13.5px;
	padding: 1px 6px;
	border-radius: 0;
	border: 2px outset white;
}
.mini-sidenav-on {
	margin-left: calc(19% - 141px);
	transition: margin-left 500ms;
}
.breadcrumb {
	font-size: 13.5px;
	border-top: 3px solid #609bbd;
}
.breadcrumb li.active {
	color: #609bbd;
}
.breadcrumb a {
	text-decoration: none;
	color: #777;
}
.breadcrumb a:hover {
	color: #609bbd;
}
.color-black {
	color: #000;
}

/* ResourceTabs.css */
.tab-container {
	display: flex;
	border-bottom: 1px solid #ccc;
	margin-bottom: 10px;
	background-color: #e3e3e3; /* Light grey background for the tabs */
}

.tab-button {
	flex: 1;
	padding: 10px;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 16px;
	text-align: center;
	border-bottom: 2px solid transparent;
}

.tab-button.active {
	border-bottom: 2px solid #609bbd;
	color: #609bbd;
	font-weight: bold;
}

.tab-button:hover {
	color: #609bbdd6;
}

.tab-content {
	padding: 16px;
	background-color: #f5f5f5; /* Light grey background for the content */
	border: 1px solid #ccc; /* Border for the content area */
	border-top: none; /* Remove top border to blend with tabs */
}

.image-container {
	display: flex;
	flex-wrap: wrap;
	gap: 16px; /* Adjust the gap value as needed */
}

.image-wrapper {
	flex: 1 1 auto;
	max-width: 150px;
}
