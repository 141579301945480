#route-view {
	padding: 15px 15px 30px;
	overflow-y: auto;
	height: inherit;
}
.main-heading {
	color: #111;
	font-size: 22px;
	font-weight: 600;
	display: flex;
}
.main-heading .side-link {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}
.main-heading .side-link .btn-side {
	background: #609bbd;
	color: white;
	font-size: 13.5px;
	padding: 1px 6px;
	border-radius: 0;
	border: 2px outset white;
}
.mini-sidenav-on {
	margin-left: calc(19% - 141px);
	transition: margin-left 500ms;
}
.breadcrumb {
	font-size: 13.5px;
	border-top: 3px solid #609bbd;
}
.breadcrumb li.active {
	color: #609bbd;
}
.breadcrumb a {
	text-decoration: none;
	color: #777;
}
.breadcrumb a:hover {
	color: #609bbd;
}
.quickstats {
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.12);
}
.quickstats-header {
	font-size: 28px;
	color: #333;
	background-color: #eee;
	padding: 4px 80px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.quickstats-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 80px;
}
.quickstats-card {
	color: #609bbd;
	padding: 30px;
	display: flex;
	flex-direction: column;
}
.quickstats-amount {
	font-weight: 700;
	font-size: 34px;
	margin-bottom: 12px;
}
.quickstats-description {
	color: gray;
	font-weight: 600;
	font-size: 18px;
}
.quickstats-card {
	padding: 30px;
	display: flex;
	flex-direction: column;
}
.quickstats-middle {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding: 30px;
}
.conversion-rate {
	font-weight: 700;
	font-size: 100px;
	margin-bottom: 12px;
	display: flex;
	justify-content: center;
}
.conversion-card {
	color: #609bbd;
	display: flex;
	flex-direction: column;
}
.middle-right {
	flex-basis: 50%;
	height: 100%;
	overflow: unset;
}
.middle-left {
	flex-basis: 30%;
}
