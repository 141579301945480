#dashboard {
	display: flex;
	justify-content: center;
	align-items: center;
}
.dashboardImage {
	max-width: 440px;
}
.advertiser-date-filters {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 10px;
	margin-bottom: 66px;
	margin-top: 1px;
}
.advertiser-date-filters input {
	padding: 5px 16px;
	border: 2px solid #609bbd;
	border-radius: 4px;
	position: relative;
	background-color: whitesmoke;
	font-size: 18px;
	cursor: unset;
}

.advertiser-date-filters input::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

.advertiser-date-filters input:first-child::before {
	content: "From";
	display: block;
	position: absolute;
	top: 0;
	transform: translateY(-60%);
	background-color: whitesmoke;
	font-size: 14px;
}
.advertiser-date-filters input:last-child:before {
	content: "To";
	display: block;
	position: absolute;
	top: 0;
	transform: translateY(-60%);
	background-color: whitesmoke;
	font-size: 14px;
}
.advertiser-card {
	cursor: pointer;
	transition: all 0.12s ease !important;
}
.advertiser-card:active {
	background-color: rgb(241, 241, 241);
	transform: scale(0.9);
}
.advertiser-card:hover {
	background-color: rgb(241, 241, 241);
}
