$sidenavColor: rgb(96, 155, 189);

$sidenavColorHover: rgba(96, 155, 189, 0.9);

$topPaddingSpace: 65.094px;

$bottomPaddingSpace: 105.094px;

$sidenavFullWidth: 19%;

$topMenuColor: white;

.dropdown-item {
	&:active {
		color: white !important;
	}
}

.bg-theme {
	background: $sidenavColorHover;

	color: white;

	&:hover {
		background: $sidenavColor;

		color: white;
	}
}

.flex-1 {
	flex: 1;
}

// @font-face {
//   font-family: "Oswald";

//   src: url("./../fonts/Oswald[wght].ttf") format("ttf");
// }

body,
html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	-webkit-touch-callout: none;

	min-height: 100%;

	-webkit-text-size-adjust: 100%;

	-ms-text-size-adjust: 100%;

	-webkit-font-smoothing: antialiased;

	padding: 0;
	margin: 0;

	background: whitesmoke;
}

body {
	font-family: "Oswald", sans-serif;

	padding-top: $topPaddingSpace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

@-webkit-keyframes miniSideNavAnimate {
	from {
		left: 0;
	}

	to {
		left: -140px;
	}
}

@keyframes miniSideNavAnimate {
	from {
		left: 0;
	}

	to {
		left: -140px;
	}
}

@-webkit-keyframes miniSideNavAnimateReverse {
	from {
		left: -140px;
	}

	to {
		left: 0;
	}
}

@keyframes miniSideNavAnimateReverse {
	from {
		left: -140px;
	}

	to {
		left: 0;
	}
}

@-webkit-keyframes miniSideNavSubmitBarAnimate {
	from {
		left: 227.66px;
		width: 82.4%;
	}

	to {
		left: 87.66;
		width: 93.6%;
	}
}

@keyframes miniSideNavSubmitBarAnimate {
	from {
		left: 227.66px;
		width: 82.4%;
	}

	to {
		left: 87.66px;
		width: 93.6%;
	}
}

@-webkit-keyframes miniSideNavSubmitBarAnimateReverse {
	from {
		left: 87.66;
		width: 93.6%;
	}

	to {
		left: 227.66px;
		width: 82.4%;
	}
}

@keyframes miniSideNavSubmitBarAnimateReverse {
	from {
		left: 87.66px;
		width: 93.6%;
	}

	to {
		left: 227.66px;
		width: 82.4%;
	}
}

@-webkit-keyframes miniSideNavAnimateHover {
	from {
		left: -140px;
	}

	to {
		left: 0;
	}
}

@keyframes miniSideNavAnimateHover {
	from {
		left: -140px;
	}

	to {
		left: 0;
	}
}

#sidenav {
	#accordion {
		padding-bottom: 150px;
	}

	* {
		border: none;
		outline: none;
	}

	// max-width: 18.666667%;

	display: flex;

	position: fixed;

	top: $topPaddingSpace;

	bottom: 0;

	z-index: 0;

	height: calc(100vh - #{$bottomPaddingSpace});

	background: $sidenavColor;

	padding: 0;

	flex-direction: column;

	overflow-x: none;

	overflow-y: scroll;

	.card {
		.card-header {
			padding: 0;

			& > a {
				display: flex;

				font-size: 12px;

				text-transform: uppercase;

				color: rgba(255, 255, 255, 0.8);

				font-weight: 600;

				padding: 20px;

				text-decoration: none;

				background: $sidenavColor;

				width: 100%;

				height: 80px;

				justify-content: space-between;

				align-items: center;

				&:hover {
					background: $sidenavColorHover;
				}
				& > i {
					display: flex;
					align-items: center;

					& > span {
						padding-left: 10px;
					}
				}
			}

			&.folded {
				background: white;

				& > a {
					position: relative;

					.arrow {
						transform: rotate(180deg);

						transition: transform 0.25s;
					}

					&.collapsed .arrow {
						transform: rotate(0deg);
					}
				}

				.arrow {
					// display: none;

					position: absolute;

					right: 20px;

					// bottom: 5px;

					font-size: 12px;

					color: white;
				}
			}

			.custom-icon img {
				height: 30px;

				width: 30px;

				display: block;

				filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
					brightness(100000%) contrast(119%);

				-webkit-filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
					brightness(100000%) contrast(119%);
			}

			.list-group {
				a {
					border-radius: 0;

					border: none;

					outline: none;

					font-size: 11.5px;

					border-left: 5px solid $sidenavColor;

					text-transform: uppercase;

					&.active {
						background: whitesmoke;

						color: #777;

						font-weight: 700;

						border-left: 5px solid royalblue;
					}
				}
			}
		}
	}
}

.sidenav-mini,
.mini-sidenav-footer {
	-webkit-animation-name: miniSideNavAnimate;

	-webkit-animation-duration: 500ms;

	-webkit-animation-fill-mode: forwards;

	animation-name: miniSideNavAnimate;

	animation-duration: 500ms;

	animation-fill-mode: forwards;
}

.sidenav-mini-hover {
	-webkit-animation-name: miniSideNavAnimateHover;

	-webkit-animation-duration: 500ms;

	-webkit-animation-fill-mode: forwards;

	animation-name: miniSideNavAnimateHover;

	animation-duration: 500ms;

	animation-fill-mode: forwards;
}

.mini-sidenav-footer {
	p {
		visibility: hidden;
	}

	i {
		transform: rotate(180deg);

		transition: transform 0.5s;
	}
}

#sidenavFooter {
	color: $sidenavColor;

	display: flex;

	position: fixed;

	bottom: 0;

	background: white;

	width: $sidenavFullWidth;

	padding: 10px 20px;

	align-items: center;

	z-index: 10;

	p {
		font-size: 12px;

		margin: 0;

		width: 100%;
	}

	i {
		font-size: 20px;

		color: $sidenavColor;

		&:hover {
			cursor: pointer;

			color: $sidenavColorHover;
		}
	}
}

#topmenu {
	background: $topMenuColor;

	padding: 0;

	.navbar-brand {
		height: 60px;

		width: 200px;

		padding: 0;

		display: flex;

		align-items: center;

		justify-content: center;

		margin: 0;

		img {
			height: 32px;
		}
	}

	.search {
		margin-bottom: 0 !important;

		.input-group-text {
			background: $sidenavColor;
		}

		.input-group-text {
			border-top-left-radius: 10px;

			border-bottom-left-radius: 10px;

			i {
				color: white;
			}
		}

		input[type="search"] {
			border-top-right-radius: 10px;

			border-bottom-right-radius: 10px;

			&:focus {
				-webkit-appearance: none;

				box-shadow: none;

				-webkit-box-shadow: none;
			}
		}
	}

	.top-control {
		&:hover {
			cursor: pointer;
		}

		.icon i {
			font-size: 25px;

			color: $sidenavColor;
		}
	}
}

.dropdown {
	.dropdown-menu {
		padding: 0;

		font-size: 13px;

		.dropdown-item {
			color: #777;
		}
	}
}

#main-view {
	// margin-left: 223px;
	// max-width: calc(100vw - 223px);

	transition: margin-left 500ms;

	// width: 90%;

	height: calc(100vh - #{$topPaddingSpace + 39px + 54px});

	background: whitesmoke;

	#route-view {
		padding: 40px 55px 100px;

		overflow-y: auto;

		height: 100vh;
	}

	.main-heading {
		color: #111;

		font-size: 22px;

		font-weight: 600;

		display: flex;

		.side-link {
			flex: 1;

			display: flex;

			justify-content: flex-end;

			.btn-side {
				background: $sidenavColor;

				color: white;

				font-size: 13.5px;

				padding: 1px 6px;

				border-radius: 0;

				border: 2px outset white;
			}
		}
	}

	&.mini-sidenav-on {
		margin-left: calc(#{$sidenavFullWidth} - 141px);

		transition: margin-left 500ms;
	}

	.breadcrumb {
		font-size: 13.5px;

		border-top: 3px solid $sidenavColor;

		li.active {
			color: $sidenavColor;
		}

		a {
			text-decoration: none;

			color: #777;

			&:hover {
				color: $sidenavColor;
			}
		}
	}
}

@mixin form-group {
	.form-group {
		padding: 20px 50% 0 20px;

		margin-bottom: 0;

		.input-group {
			.input-group-prepend {
				.input-group-text {
					border-right: 0;
					padding-left: 5px;
					padding-right: 2px;
				}
			}

			input.after {
				border-left: 0;
			}

			.input-group-text {
				height: 25px;

				border-radius: 0;

				background: white;
			}
		}
	}
}

@mixin input-styles {
	label {
		margin-bottom: 5px;
		font-size: 13px;
		color: #333;

		&.required::after {
			content: "*";

			color: red;

			margin-left: 0.5px;
		}
	}

	input,
	textarea,
	select,
	.list-group .list-group-item {
		font-size: 12.5px;

		color: black;

		border-radius: 0;

		&:focus {
			box-shadow: none;
		}
	}

	.list-group .list-group-item {
		height: 25px;

		padding: 15px;

		display: flex;

		align-items: center;
	}

	input,
	select {
		padding: 0 5px;

		height: 25px;
	}

	textarea {
		padding: 5px;
	}

	.info-text {
		color: grey;
	}
}

.table-filter-panel {
	margin-top: 20px;
	.form-group {
		margin-bottom: 0;
	}
	@include input-styles;
}

.form-panel {
	.macros {
		padding-right: 30%;

		margin-top: 10px;

		input[type="search"] {
			height: 30px;
		}
	}

	.list-group {
		max-height: 250px;

		overflow-y: scroll;

		padding-top: 5px;

		padding-bottom: 5px;

		border: 0.5px solid lightgrey;

		border-top: 0;

		.list-group-item {
			border: 0;

			display: flex;

			justify-content: space-between;

			.right {
				color: #888;
			}
		}
	}

	@include form-group;

	font-size: 13px;

	background: white;

	border: 0.5px solid lightgrey;

	padding: 0 0 20px;

	&.offer {
		.heading {
			padding: 25px 20px;
		}

		&::before {
			display: none;
		}

		padding: 0;

		.form-group {
			padding-right: 30%;
		}

		.form-group:last-child {
			padding-bottom: 30px;
		}

		.caps-row {
			display: flex;
			justify-content: space-between;
			.cap-item {
				width: 50%;
			}
		}
	}

	box-shadow: 0px 1px 8px -3px #777;

	margin-top: 10px;

	position: relative;

	.heading {
		border-top: 5px solid $sidenavColor;

		&.collapsed {
			border-left: 5px solid $sidenavColor;

			border-top: 0;
		}

		padding: 20px 50% 20px 20px;

		i.fas {
			display: none;
		}

		&[data-toggle="collapse"] {
			span,
			i.fas {
				color: black;
			}

			i.fas {
				font-size: 22px;
			}

			i.fa-plus {
				display: none;
			}

			i.fa-minus {
				display: inline-block;
			}

			&.collapsed {
				span,
				i.fas {
					color: grey;
				}

				i.fa-plus {
					display: inline-block;
				}

				i.fa-minus {
					display: none;
				}
			}

			display: flex;

			justify-content: space-between;

			&:hover {
				cursor: pointer;

				//background: #1111;
			}
		}

		font-size: 18px;

		font-weight: 600;

		color: grey;
	}

	@include input-styles;

	.form-submit-bar {
		display: flex;

		position: fixed;

		bottom: 0;

		background: #e9ecef;

		width:  calc(100% - 237.66px);

		left: 237.66px;

		padding: 10px 40px;

		border-top: 3px solid #609bbd;

		z-index: 5;

		justify-content: flex-end;

		// -webkit-animation-name: miniSideNavSubmitBarAnimateReverse;

		-webkit-animation-duration: 500ms;

		-webkit-animation-fill-mode: forwards;

		// animation-name: miniSideNavSubmitBarAnimateReverse;

		animation-duration: 500ms;

		animation-fill-mode: forwards;

		&.sidenav-mini-enable {
			-webkit-animation-name: miniSideNavSubmitBarAnimate;

			-webkit-animation-duration: 500ms;

			-webkit-animation-fill-mode: forwards;

			animation-name: miniSideNavSubmitBarAnimate;

			animation-duration: 500ms;

			animation-fill-mode: forwards;
		}

		div:first-child {
			flex: 1;

			display: flex;

			align-items: center;

			justify-content: flex-end;

			.errors {
				font-size: 14.5px;

				color: #af3e1c;

				padding-right: 20px;

				text-decoration: none;
			}

			.show {
				background: white;

				min-height: 1px;

				position: absolute;

				z-index: 100000;

				padding: 20px;

				border: 2px solid;

				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);

				bottom: -20px;
			}
		}

		.btn-submit {
			background: $sidenavColor;

			color: white;

			&:hover {
				background: $sidenavColorHover;
			}
		}
	}

	.check-tree {
		text-transform: uppercase;

		.check-tree-child {
			background: whitesmoke;

			padding: 10px;

			border-radius: 5px;

			margin: 0 0 25px 55px;
		}

		.custom-control-label {
			padding-top: 3px;

			&.minus::after {
				// background-image: url("/asset/icons/minus.svg");

				background-size: 60% 78%;
			}
		}

		.node {
			margin-bottom: 10px;
		}

		a {
			text-decoration: none;

			&.collapsed::before {
				content: "+";
			}

			&::before {
				content: "-";
			}

			&.collapsed::before,
			&::before {
				font-weight: 800;

				font-size: 25px;

				color: $sidenavColor;

				margin-right: 10px;
			}
		}
	}

	&::before {
		content: "Fields with * are required";

		position: absolute;

		color: red;

		right: 10px;

		top: 20px;

		outline: 1px solid $sidenavColor;

		border-left: 10px solid $sidenavColor;

		padding: 1px 5px 1px 11px;
	}
}

.main-heading.offer::before {
	content: "Fields with * are required";

	position: absolute;

	color: red;

	font-size: 13.5px;

	font-weight: 500;

	right: 10px;

	outline: 1px solid $sidenavColor;

	border-left: 10px solid $sidenavColor;

	padding: 2px 5px 2px 11px;
}

.custom-control-label {
	padding-top: 5px;
}

#notfications {
	position: fixed;

	right: 0;

	top: 114px;

	letter-spacing: 0.2px;

	font-size: 14.5px;

	box-shadow: 6px 2px 3px 2px rgba(0, 0, 0, 0.1);

	background: springgreen;

	.alert {
		border-radius: 0;

		border: none;

		margin: 0;
	}
}

.dataTable {
	position: relative;

	background: white;

	border: 1px solid $sidenavColor;

	font-size: 12px;

	overflow-x: auto;

	overflow-y: auto;

	margin-top: 20px;

	border-left: 5px solid $sidenavColor;

	a {
		text-decoration: none;

		&.profile-view {
			&:hover {
				&::before {
					content: "\21F1";

					margin-right: 3px;

					font-size: 14.5px;

					color: #555;
				}
			}
		}
	}

	.table-fix {
		width: 98.6%;

		th {
			border-bottom: 0.5px solid #7777;

			word-break: break-word;
		}
	}

	.table-responsive {
		overflow-y: scroll;

		height: 55vh;
	}

	table {
		width: 100%;

		margin-bottom: 0;

		.head-hidden {
			th {
				padding: 0;
			}
		}

		.no-record {
			padding: 20px;

			text-align: center;

			color: #777;
		}

		thead {
			th {
				border: 0;

				padding: 10px;
			}
		}

		tbody {
			td {
				padding: 10px;

				vertical-align: middle;

				border-bottom: 0.5px solid #2222;

				word-break: break-word;

				&.row-edit {
					i.fa-cog {
						font-size: 15px;

						&:hover {
							cursor: pointer;

							transform: rotate(360deg);

							transition: transform 0.5s;
						}

						transform: rotate(-360deg);

						transition: transform 0.5s;
					}
				}
			}
		}
	}
}

.my-account-main {
	grid-template-columns: auto auto;
}

.my-account-main-full {
	grid-template-columns: auto;
}

.my-account-main,
.my-account-main-full {
	display: grid;

	grid-gap: 15px;

	margin-top: 15px;
}

.my-account-main,
.my-account-main-full {
	.my-account {
		height: fit-content;

		-webkit-height: fit-content;

		font-size: 14px;

		position: relative;

		display: grid;

		grid-template-columns: auto;

		grid-gap: 15px;

		a {
			text-decoration: none;
			font-size: 14px;
		}

		.account {
			background: white;

			border: 5px outset $sidenavColor;

			padding: 15px;

			border-bottom: 0.5px solid #2222;

			border-right: 0.5px solid #2222;

			border-radius: 10px;

			height: fit-content;

			-webkit-height: fit-content;

			.head {
				display: flex;
				justify-content: space-between;
				align-items: center;

				margin-bottom: 20px;

				.left {
					font-size: 20px;

					font-weight: 600;

					color: #777;
				}
			}

			.body {
				.body-row {
					display: flex;
					justify-content: space-between;
					align-items: center;

					margin-bottom: 10px;

					.left {
						color: #888;
					}

					.right {
						color: #444;

						width: 70%;

						text-align: right;
					}
				}

				.body-row-counter {
					.list-counter {
						display: flex;
						justify-content: space-between;
						align-items: center;

						margin-bottom: 10px;

						.left {
							color: #888;
						}

						.right {
							color: #444;

							width: 50%;

							text-align: right;
						}
					}

					.circle-counter {
						display: flex;

						justify-content: space-evenly;

						.circle {
							display: flex;

							flex-direction: column;

							justify-content: center;

							align-items: center;

							border: 10px outset rgba(96, 155, 189, 0.9);

							height: 120px;

							border-radius: 100%;

							width: 120px;

							&.active {
								border-color: rgba(79, 168, 70, 0.9);
							}

							&.pending {
								border-color: rgba(248, 192, 23, 0.9);
							}

							&.suspended {
								border-color: rgba(107, 116, 124, 0.9);
							}

							&.inactive {
								border-color: rgba(221, 77, 68, 0.9);
							}

							.count {
								font-size: 26px;

								color: #666;
							}

							.text {
								color: #8888;

								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}

.btn-loading {
	//   background: url("/asset/loaders/btn-loader.gif");

	background-size: 64px;

	background-position: center;

	color: transparent;

	border: 0;

	outline: 0;

	height: 32px;
}

#loader {
	position: absolute;

	z-index: 9999999999999999999999999999999999;

	background: #4444;

	width: inherit;

	height: 100%;

	left: 0;

	top: 0;

	.inner {
		// background: url("/asset/loaders/Ads-Paying-media-logo_512px.gif");

		background-repeat: no-repeat;

		background-size: 128px;

		height: inherit;

		width: inherit;

		background-position: center;
	}
}

.select2 {
	font-size: 12.5px !important;

	.select2-selection {
		border-radius: 0 !important;

		border: 0 !important;

		min-height: 25px !important;
	}

	ul {
		li {
			margin: 2px !important;

			border-radius: 2px !important;

			padding: 2px !important;

			border: 0 !important;

			&.select2-selection__choice {
				background: $sidenavColor !important;

				* {
					color: white !important;
				}

				color: white !important;
			}

			span {
				margin-right: 4px !important;
			}
		}
	}

	border: 1px solid #ced4da !important;
}

.dropdown.profile {
	.dropdown-item,
	.dropdown-item.active {
		&:hover {
			background: #1111;
		}
	}

	.dropdown-item.active {
		background: transparent;
	}
}

@keyframes offer-bar-hide {
	from {
		right: 350px;
		width: 0;
	}
	to {
		right: 0;
		width: 350px;
	}
}

@keyframes offer-bar-show {
	from {
		right: 0;
		width: 350px;
	}
	to {
		right: 350px;
		width: 0;
	}
}

#offer-bar {
	display: flex;
	align-items: flex-start;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100000;
	.bar-toggle {
		height: 48px;
		display: flex;
		align-items: center;
		background: white;
		color: $sidenavColorHover;
		width: 48px;
		justify-content: center;
		font-size: 20px;
		outline: 0;
		padding: 0;
		&:hover {
			cursor: pointer;
		}
		&:active {
			background: $sidenavColor;
			color: white;
		}
	}
	.list-group {
		max-height: calc(100vh - (#{$topPaddingSpace} + #{$bottomPaddingSpace}));
		overflow: auto;
		background: whitesmoke;
		position: relative;
		width: 400px;
		&.offer-bar-hide {
			width: 0px;
			overflow: none;
			transition: width 500ms;
			* {
				color: transparent !important;
			}
		}
		&.offer-bar-show {
			width: 400px;
			overflow: auto;
			transition: width 500ms;
			* {
				color: #495057;
			}
		}
		.list-group-item {
			border: 0;
			outline: 0;
			border-radius: 0%;
			&.active {
				background: $sidenavColor;
				color: white;
			}
		}
	}
}

.table-supplies {
	@include input-styles;
	.top-filters {
		position: relative;
		z-index: 999;
	}
	.top-filters,
	.bottom-filters {
		display: flex;
		margin-top: 30px;
		margin-bottom: -10px;
	}
	.bottom-filters {
		margin-top: 10px;
	}
	.paginate-line {
		display: flex;
		list-style: none;
		padding-left: 0;
		margin: 0 0 0 10px;
		max-width: 220px;
		overflow-x: auto;
		li {
			a {
				color: $sidenavColor;
				border-radius: 0 !important;
				padding: 2.5px 5px;
				font-size: 12px;
			}
		}
	}
}
.form-inputs {
	input,
	select,
	textarea {
		background: white !important;
		font-size: 12.5px !important;
		border: 1px solid #ced4da !important;
		padding: 0 5px;
		height: 25px;
		font-size: 12.5px;
		color: black;
		border-radius: 0;
		background: rgba(0, 0, 0, 0.05);
	}
}
.alignCenter {
	display: flex;
	align-items: center;
}
.clickable {
	cursor: pointer;
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #609BBD #DFE9EB;
}*/

/* Chrome, Edge and Safari */
#sidenav::-webkit-scrollbar {
  height: 8px;
  width: 6px;
}
#sidenav::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #DFE9EB;
}

#sidenav::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

#sidenav::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

#sidenav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:  #9ecde8;
	// border-left: 2px solid black;

}

#sidenav::-webkit-scrollbar-thumb:hover {
  background-color: #bcd9ea;
}

#sidenav::-webkit-scrollbar-thumb:active {
  background-color: #7eb0cd;
}
//////////////
/// 
#route-view::-webkit-scrollbar {
  height: 8px;
  width: 12px;
}
#route-view::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #DFE9EB;
}

#route-view::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

#route-view::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

#route-view::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:  #9ecde8;
	// border-left: 2px solid black;

}

#route-view::-webkit-scrollbar-thumb:hover {
  background-color: #bcd9ea;
}

#route-view::-webkit-scrollbar-thumb:active {
  background-color: #7eb0cd;
}