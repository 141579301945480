.App {
  text-align: center;
}
#detail {
  flex: 1;
  padding-top: 8%;
  padding-left: 220px;
  padding-right: 2%;
  width: 100%;
}

#detail.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}
.table-cell {
    word-wrap: break-word;
    white-space: normal;
}
#dashboard {
	display: flex;
	justify-content: center;
	align-items: center;
}
.dashboardImage {
	max-width: 440px;
}
.publisher-date-filters {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 10px;
	margin-bottom: 66px;
	margin-top: 1px;
}
.publisher-date-filters input {
	padding: 5px 16px;
	border: 2px solid #609bbd;
	border-radius: 4px;
	position: relative;
	background-color: whitesmoke;
	font-size: 18px;
	cursor: unset;
}

.publisher-date-filters input::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

.publisher-date-filters input:first-child::before {
	content: "From";
	display: block;
	position: absolute;
	top: 0;
	transform: translateY(-60%);
	background-color: whitesmoke;
	font-size: 14px;
}
.publisher-date-filters input:last-child:before {
	content: "To";
	display: block;
	position: absolute;
	top: 0;
	transform: translateY(-60%);
	background-color: whitesmoke;
	font-size: 14px;
}
.publisher-card {
	cursor: pointer;
	transition: all 0.12s ease !important;
}
.publisher-card:active {
	background-color: rgb(241, 241, 241);
	transform: scale(0.9);
}
.publisher-card:hover {
	background-color: rgb(241, 241, 241);
}
.chart-container {
	border-radius: 12px;
	padding: 50px;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
}


#sidenav {
  width: 250px; /* Adjust as needed */
  transition: width 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  flex-shrink: 0;
}

#sidenav.collapsed {
  width: 75px; /* Adjust as needed */
}

.fit-outlet-container {
  flex-grow: 1;
  margin-left: 250px; /* This matches the width of the expanded sidebar */
  transition: margin-left 0.3s ease; /* Smooth transition */
}

.sidebar-content.collapsed + .fit-outlet-container {
  margin-left: 75px; /* This matches the width of the collapsed sidebar */
}
.sidebar-button{
	margin-left: 16px;
	border: none;
	border-radius: 5px;
}
.sidebar-button:hover{
 background-color: rgb(224, 224, 224);
}